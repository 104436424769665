import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../../images/undersidor/pdf_faktura.svg";
import dokument_img from '../../images/undersidor/pdf_faktura.png';
import pdf_tolkning_img from '../../images/undersidor/pdf_tolkning.png';

const KONTAKT_BOT = "botxWuWV";
const DEMO_BOT = "WfgujSpL";

function Papper_pdf() {
    const [kontakt_1, set_kontakt_1] = useState(false);
    const [demo_1, set_demo_1] = useState(false);
    const [demo_2, set_demo_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>PDF-fakturor | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Tolka och automatisera PDF fakturor" />
                <meta name="keywords" content="PDF fakturor" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Tolka och automatisera PDF fakturor" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/leverantorsfakturor/papper_pdf" />
                <link rel="cannonical" href="https://www.crediflow.se/leverantorsfakturor/papper_pdf" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">PDF-fakturor</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Dina fakturor analyseras av AI teknologi som hittar 
                                        och extraherar data på huvud- eller radnivå. 
                                        Automatisk matchning av dina leverantörer och 
                                        avancerad kunskapsupplärning ger dig ökad 
                                        automatisering och säkerhet.
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/leverantorsfakturor" className="flex items-center text-blue-800 hover:text-blue-600">leverantörsfakturor</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">PDF-fakturor</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Tolkning av PDF fakturor</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Hanteringen av PDF fakturor skiljer sig 
                                från e-faktura. PDF fakturor dvs inskannade 
                                pappersfakturor eller PDF:er bifogade i 
                                mail behöver tolkas.
                                <br/><br/>
                                Tolkning innebär att vi kontrollerar leverantören 
                                och läser ut den information som du behöver från 
                                fakturan. Vi erbjuder tolkning på huvud eller 
                                artikelnivå beroende på dina behov. Tolkade data ska 
                                alltid kontrolleras eller verifieras innan de levereras 
                                vidare till nästkommande system vilket gör att du i 
                                ditt system alltid har kontrollerad och korrekt fakturadata.
                                <br/><br/>
                                <span className="font-bold">PDf fakturor via mail &#8211; mindre hantering än pappersfakturor</span>
                                <br/>
                                Hanteringen av en mailfaktura är smidigare än en pappersfaktura 
                                då du slipper lägga tid på posthantering och skanning. 
                                Fakturan kommer in direkt till tolken och data läses 
                                ut. Du väljer om vi ska kontrollera data 
                                eller om du vill göra det själv.
                            </p>
                            <button onClick={() => set_demo_1(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                            
                            {demo_1 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={DEMO_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={pdf_tolkning_img} alt="Tolkning av PDF fakturor" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={dokument_img} alt="Pappersfakturor" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Pappersfakturor &#8211; kräver viss hantering</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Behåll kontroll och arbete inhouse eller låt 
                                oss göra det så att du inte behöver lägga någon tid alls!
                                <br/><br/>
                                De flesta företagen får fortfarande in fakturor via 
                                post. Pappersfakturor innebär mer arbete än fakturor 
                                via mail då post ska hanteras och fakturan 
                                skannas innan tolkning och kontroll.
                                <br/><br/>
                                Med oss väljer du om du vill skanna fakturorna 
                                själv eller ställa om din fakturaadress och 
                                låta oss ta hand om det arbetet.
                                <br/><br/>
                                Du väljer också om vi ska kontrollera fakturorna 
                                åt dig eller om du vill göra det själv. Flexibiliteten 
                                i systemet gör det möjligt att låta oss kontrollera 
                                delar eller hela ditt flöde under den tid du önskar.
                            </p>
                            <button onClick={() => set_demo_2(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                            {demo_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={DEMO_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Papper_pdf;